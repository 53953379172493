import clsx from 'clsx';

import { LinkButton } from '../_core/button';
import { WEB_EXTENSION_INFO_BY_BROWSER } from '../_core/constants';
import {
  InstallAppStoreLink,
  InstallGooglePlayLink,
} from '../_core/mobileAppLinks/mobileAppLinks';
import { useEntryContext } from '../entry';
import * as styles from './hero.module.css';

export function HomeHero() {
  const { browser } = useEntryContext();

  const webExtensionInfo =
    WEB_EXTENSION_INFO_BY_BROWSER[browser === 'unknown' ? 'chrome' : browser];

  return (
    <div className={styles.root}>
      <h1 className={styles.heading}>
        <span
          className={styles.fancyGradientText}
          style={{
            backgroundImage:
              'linear-gradient(105deg, #22fff2 38%, #0a23fc 55%, #030f2f 92%)',
          }}
        >
          Keep
        </span>{' '}
        crypto changing bold
        <span
          className={styles.fancyGradientText}
          style={{
            backgroundImage:
              'linear-gradient(120deg, #22fff2 22%, #0a23fc 35%, #030f2f 50%, #fb36ff 80%)',
          }}
        >
          er
        </span>
      </h1>

      <div className={styles.descriptionAndAppLink}>
        <p className={styles.description}>
          Keeper Wallet is High-security wallet extension with swapping
          capabilities point to the Waves blockchain its services.
        </p>

        <LinkButton
          className={styles.appLink}
          to="https://web.keeper-wallet.app"
          size="big"
        >
          Go to the app
        </LinkButton>
      </div>

      <div className={styles.install}>
        <div className={styles.installBox}>
          <p
            className={clsx(styles.installBoxCta, styles.installBoxCta_mobile)}
          >
            <span>
              <span className={styles.installBoxCtaPart}>
                Or Install wallet
              </span>{' '}
              <span className={styles.installBoxCtaPart}>on your phone</span>
            </span>
          </p>

          <div className={styles.installBoxLinks}>
            <InstallGooglePlayLink />
            <InstallAppStoreLink />
          </div>
        </div>

        <div className={styles.installBox}>
          <p
            className={clsx(
              styles.installBoxCta,
              styles.installBoxCta_webExtension,
            )}
          >
            <span>
              <span className={styles.installBoxCtaPart}>Or Install</span>{' '}
              <span
                className={clsx(
                  styles.installBoxCtaPart,
                  styles.installBoxCtaAccent,
                )}
              >
                extension for browsers
              </span>
            </span>
          </p>

          <div className={styles.installBoxLinks}>
            <a
              className={styles.installLinkWebExtension}
              href={webExtensionInfo.storeUrl}
              rel="noopener"
              target="_blank"
            >
              <img
                alt=""
                src={webExtensionInfo.logoPathname}
                width={24}
                height={24}
              />
              {webExtensionInfo.storeName}
            </a>
          </div>
        </div>
      </div>

      <div className={styles.image} />
    </div>
  );
}
