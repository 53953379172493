import clsx from 'clsx';
import { Link } from 'react-router-dom';

import * as styles from './iconButton.module.css';

export function IconLinkButton({
  className,
  ...otherProps
}: React.ComponentProps<typeof Link>) {
  // eslint-disable-next-line react/jsx-no-target-blank
  return <Link className={clsx(className, styles.icon)} {...otherProps} />;
}
