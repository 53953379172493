import { type BrowserName } from '../_core/detectBrowser';

export const WEB_EXTENSION_INFO_BY_BROWSER: Record<
  Exclude<BrowserName, 'unknown'>,
  {
    logoPathname: string;
    storeName: string;
    storeUrl: string;
  }
> = {
  chrome: {
    logoPathname: new URL('./chrome.svg', import.meta.url).pathname,
    storeName: 'Chrome Browser Addons',
    storeUrl:
      'https://chrome.google.com/webstore/detail/lpilbniiabackdjcionkobglmddfbcjo',
  },
  edge: {
    logoPathname: new URL('./edge.svg', import.meta.url).pathname,
    storeName: 'Edge Browser Addons',
    storeUrl:
      'https://microsoftedge.microsoft.com/addons/detail/hdpempkibblfcglmkkakkpnjmbnebaki',
  },
  firefox: {
    logoPathname: new URL('./firefox.svg', import.meta.url).pathname,
    storeName: 'Firefox Browser Addons',
    storeUrl: 'https://addons.mozilla.org/firefox/addon/waves-keeper/',
  },
  opera: {
    logoPathname: new URL('./opera.svg', import.meta.url).pathname,
    storeName: 'Opera Browser Addons',
    storeUrl: 'https://addons.opera.com/extensions/details/waves-keeper/',
  },
};
