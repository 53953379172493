import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { FocusOn } from 'react-focus-on';
import { Transition } from 'react-transition-group';

import * as styles from './bottomSheet.module.css';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

export function BottomSheet({ children, isOpen, onClose }: Props) {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  return isReady
    ? createPortal(
        <Transition
          in={isOpen}
          mountOnEnter={false}
          nodeRef={nodeRef}
          unmountOnExit={false}
          timeout={{ exit: 400 }}
        >
          {transitionStatus => (
            <FocusOn
              ref={nodeRef}
              autoFocus={false}
              enabled={transitionStatus !== 'exited'}
            >
              <div
                className={clsx(
                  styles.overlay,
                  {
                    unmounted: undefined,
                    entering: styles.overlayEntering,
                    entered: styles.overlayEntered,
                    exiting: styles.overlayExiting,
                    exited: styles.overlayExited,
                  }[transitionStatus],
                )}
                onClick={() => {
                  onClose();
                }}
              />

              <div
                className={clsx(
                  styles.content,
                  {
                    unmounted: undefined,
                    entering: styles.contentEntering,
                    entered: styles.contentEntered,
                    exiting: styles.contentExiting,
                    exited: styles.contentExited,
                  }[transitionStatus],
                )}
              >
                {children}
              </div>
            </FocusOn>
          )}
        </Transition>,
        document.body,
      )
    : null;
}
