import * as styles from './connectWeb3.module.css';

export function ConnectWeb3() {
  return (
    <div className={styles.root}>
      <img
        alt=""
        className={styles.picture}
        src={new URL('./connectWeb3.webp', import.meta.url).pathname}
      />

      <div className={styles.text}>
        <h2 className={styles.heading}>
          <span className={styles.fancyGradientText}>Connect to</span> Any Web3
          Service
        </h2>

        <p className={styles.description}>
          Log in to dApps and websites seamlessly and instantly. One account to
          rule them all.
        </p>
      </div>
    </div>
  );
}
