import { LinkButton } from '../_core/button';
import { SectionDescription, SectionHeading } from './common';
import * as styles from './swapWidget.module.css';

export function SwapWidget() {
  return (
    <div className={styles.root}>
      <div className={styles.pictureShadow} />
      <div className={styles.content}>
        <SectionHeading>
          <span className={styles.fancyHeading}>Integrate</span> <br />
          Swap Widget
        </SectionHeading>
        <SectionDescription>
          A powerful swapping tool for any crypto project. Easy to deploy with
          minimal code.
        </SectionDescription>

        <LinkButton to="mailto:support@keeper-wallet.app" size="big">
          CONTACT US
        </LinkButton>
      </div>

      <div className={styles.illustration}>
        <img
          alt=""
          className={styles.illustrationImage}
          src={
            new URL('./swapWidgetIllustration.svg', import.meta.url).pathname
          }
        />
      </div>
    </div>
  );
}
