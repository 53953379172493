import * as styles from './statistics.module.css';

export function Statistics() {
  return (
    <div className={styles.card}>
      <div className={styles.box}>
        <div className={styles.term}>
          <img
            alt=""
            src={new URL('./user.svg', import.meta.url).pathname}
            width={24}
            height={24}
          />
          Active users
        </div>
        <span className={styles.description}>1M+</span>
      </div>

      <div className={styles.box}>
        <div className={styles.term}>
          <img
            alt=""
            src={new URL('./download.svg', import.meta.url).pathname}
            width={24}
            height={24}
          />
          Downloads
        </div>
        <span className={styles.description}>1M+</span>
      </div>

      <div className={styles.box}>
        <div className={styles.term}>
          <img
            alt=""
            src={new URL('./bar-chart.svg', import.meta.url).pathname}
            width={24}
            height={24}
          />
          Daily volume
        </div>
        <span className={styles.description}>1M+</span>
      </div>

      <div className={styles.box}>
        <div className={styles.term}>
          <img
            alt=""
            src={new URL('./checked.svg', import.meta.url).pathname}
            width={24}
            height={24}
          />
          Established
        </div>
        <div className={styles.description}>2019</div>
      </div>
    </div>
  );
}
