import { LastUpdate, RunningText } from '../_core/runningText';

export function TermsOfUsePage() {
  return (
    <RunningText>
      <h1>KEEPER WALLET TERMS OF USE</h1>

      <LastUpdate>Last Update: February 18, 2022</LastUpdate>

      <p>
        The following Terms of Use (“<strong>Terms</strong>”) govern your use
        (the term “<strong>use</strong>” will mean access or use, as applicable)
        to our browser extension Keeper Wallet allowing you to make easy
        interactions with third party websites and applications accepting Waves
        blockchain compatible crypto-payments with digital wallets of the Waves
        blockchain, and enabling ordinary websites and applications to interact
        with the Waves blockchain, while keeping the user in control over the
        transactions they approve. Keeper Wallet and any other related features,
        tools, materials,or services offered are referred to the “
        <strong>Services</strong>”. Please read these Terms of Use carefully
        before using the Keeper.
      </p>

      <p>
        These Terms of Use govern your usage of Keeper Wallet owned by DLTech
        Ltd (reg. number 216295) (“<strong>Company</strong>”, “
        <strong>us</strong>”, “<strong>we</strong>”). The term “
        <strong>you</strong>” or “<strong>User</strong>” refers to the person or
        entity browsing or otherwise Using the Keeper Services or receiving any
        Services. A copy of these Terms of Use may be downloaded, saved, and
        printed for your reference.
      </p>

      <p>
        <strong>
          BY ACCESSING AND USING THE SERVICES, YOU ACCEPT AND AGREE TO BE BOUND
          BY AND COMPLY WITH THESE TERMS OF USE. IF YOU DO NOT ACCEPT AND AGREE
          TO BE BOUND BY THESE TERMS OF USE, PLEASE DO NOT ACCESS OR USE THE
          KEEPER WALLET.
        </strong>
      </p>

      <p>
        <strong>
          THE COMPANY BELIEVES THAT THE INFORMATION PRESENTED IS ACCURATE AND
          THAT THE INFORMATION WAS OBTAINED FROM SOURCES THAT THE COMPANY
          BELIEVES TO BE RELIABLE. HOWEVER, THE COMPANY DOES NOT GUARANTEE THE
          ACCURACY OR COMPLETENESS OF THE INFORMATION. SUCH INFORMATION IS
          SUBJECT TO CHANGE WITHOUT NOTICE.
        </strong>
      </p>

      <p>
        <strong>
          DECISIONS TO USE, HOLD, TRANSFER OR MAKE ANY TRANSACTIONS WITH
          CRYPTOCURRENCIES AND OTHER CRYPTO-ASSETS INVOLVE RISK AND ARE BEST
          MADE BASED ON THE ADVICE OF QUALIFIED PROFESSIONALS. ANY DEALING WITH
          CRYPTOCURRENCIES AND OTHER CRYPTO-ASSETS INVOLVES A RISK OF
          SUBSTANTIAL LOSSES. BEFORE UNDERTAKING ANY ACTION AND USING KEEPER
          WALLET, YOU SHOULD CONSULT A QUALIFIED PROFESSIONAL. PLEASE CONSIDER
          CAREFULLY WHETHER SUCH DEALING WITH CRYPTO-ASSETS IS SUITABLE FOR YOU
          IN LIGHT OF YOUR FINANCIAL CONDITION AND ABILITY TO BEAR FINANCIAL
          RISKS. UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR ANY LOSS OR
          DAMAGE YOU OR ANYONE
        </strong>
      </p>

      <p>
        <strong>
          ELSE INCURS AS A RESULT OF ANY CRYPTO-TRANSFER AND/OR ANY OTHER
          TRANSACTION THAT YOU OR ANYONE ELSE ENGAGES IN.
        </strong>
      </p>

      <h2>1. KEEPER WALLET</h2>

      <p>
        Keeper Wallet browser extension is a software application extending the
        functionality of web browsers in order to enable users to make easy
        interactions with third party websites and applications accepting Waves
        blockchain compatible crypto-payments with User wallets, and websites
        and applications to interact with the Waves blockchain through the
        Keeper Wallet API, while the user keeps his/her SEED safe and is able to
        approve transactions without entering private SEED on third-parties web
        resources and applications.
      </p>

      <p>
        To use Keeper Wallet, you have to have and provide SEED phrase of Waves
        blockchain wallet owned by you exclusively and under your sole and full
        control (“<strong>User Wallet</strong>”).
      </p>

      <p>
        Using the Keeper Wallet, you are solely responsible for keeping your
        SEED. We will encrypt your SEED with a password you provide for the
        Keeper Wallet. We do not store your SEED or your Keeper Wallet password
        and never send them to our servers. This secret information is stored
        locally on your device.
      </p>

      <p>
        When you visit third party websites and applications accepting Waves
        blockchain compatible crypto payments with a browser with installed and
        running a Keeper Wallet browser extension with connected User Wallet,
        our application allows:
      </p>

      <ul>
        <li>
          YOU to make the Waves blockchain compatible crypto payments to such
          third-party services and websites from your connected User Wallet
          without sharing your SEED and passwords related to such User Wallet
          with such websites and service;
        </li>

        <li>
          <p>
            THIRD PARTY SERVICES AND WEBSITES to request your User Wallet
            balance and/or Waves blockchain compatible crypto payments through
            the Keeper Wallet when you are interacting with such third-party
            websites and services.
          </p>

          <p>
            The Keeper Wallet never initiates an/or makes decision to make a
            transaction from your User Wallet to any third-party websites,
            applications or services itself. Keeper Wallet may show your User
            Wallet balance and/or facilitate transactions from or to your User
            Wallet only with your prior approval. You agree and understand that
            you are always responsible for your own conduct, acts and omissions.
            The Company is not liable for any losses that Users may sufferdue to
            their decisions to show their User Wallet balance, make
            transactions, or make other actions (or inactions) through or with
            the help of the Keeper Wallet.
          </p>

          <p>
            Users are to acknowledge that neither the Company nor any person or
            legal entity affiliated with the Company are involved in the
            transactions or actions made using the
          </p>

          <p>Keeper Wallet.</p>
        </li>
      </ul>

      <h2>2. MODIFICATION OF TERMS OF USE</h2>

      <p>
        Any updates of these Terms will be effective no sooner than the day they
        are publicly posted. If you do not want to agree to any changes made to
        the Terms of Use, you should stop using the Keeper Wallet, because by
        continuing to use the Keeper Wallet you indicate your agreement to be
        bound by the updated terms.
      </p>

      <p>
        The Company may from time to time set up and/or change the Terms. The
        Company reserves the right at any time and in its sole discretion to
        suspendand/or cancel provision of the Services for any users for their
        violation of the present Terms and/or otherwise at the Company’s own
        discretion. The Company may terminate provision of Services in part or
        in full at any time.
      </p>

      <h2>3. ELIGIBILITY</h2>

      <p>
        You hereby represent and warrant that you are fully able and competent
        to enter the terms, conditions, obligations, affirmations,
        representations, and warranties set forth in these Terms and to abide by
        and comply with these Terms.
      </p>

      <p>By using the Services, you confirm that:</p>

      <ul>
        <li>you are at least 18 (eighteen) years old;</li>

        <li>
          you are of legal age to accept these Terms according to your residence
          country legislation;
        </li>

        <li>
          you comply with the laws and regulations of your country of residence;
        </li>

        <li>you have a full capacity and authority to enter these Terms;</li>

        <li>
          <p>
            you have a valid User Wallet or will create a User Wallet for using
            the Keeper Wallet. You agree and understand that you are always
            responsible for your own conduct, acts and omissions.
          </p>

          <p>
            You agree to indemnify and hold harmless the Company and any of its
            affiliated persons against all suits, claims, costs, losses, damages
            or demands (including property loss, tax claims, infringements of
            intellectual or personal rights) in case of your non-compliance with
            these Terms.
          </p>
        </li>
      </ul>

      <h2>4. USER WARRANTIES AND REPRESENTATIONS</h2>

      <p>
        By entering these Terms and Conditions you shall warrant and represent
        that:
      </p>

      <ul>
        <li>you have full capacity to contract under applicable law;</li>

        <li>
          you have all rights to the User Wallet you are making any actions or
          transactions with;
        </li>

        <li>
          you will only be transacting via the Services with legally obtained
          funds that belong to you;
        </li>

        <li>
          you will not be performing, undertaking, engaging in, aiding, or
          abetting any unlawful activity through your relationship with us or
          through your use of the Services;
        </li>

        <li>
          you will not use the Services for illegal purposes, including money
          laundering of criminalproceeds, transfer or receipt of payment for
          planning, preparation or commitment of crime,for financing the
          terrorism and illegal trade;
        </li>

        <li>
          you will not use the Services for any purpose prohibited by these
          Terms or in any mannerthat could damage, disable, overburden, or
          impair the Company.
        </li>
      </ul>

      <h2>5. ACCOUNT PASSWORD AND SECURITY</h2>

      <p>
        When setting up an account within Company, you will be responsible for
        keeping your own account secret seed phrases for the access of any of
        your User Wallets and a separate password for Keeper Wallet which you
        choose and set up. Company encrypts this information locally at your
        computer with a Keeper Wallet password you provide, we never send any of
        your secret information (including Seed phrase and Keeper Wallet
        password) to our servers.
      </p>

      <p>
        You agree to (a) never use the same password for Keeper Wallet that you
        have ever used outside of this Services and (b) keep your secret
        information and password confidential and do not share them with anyone
        else. Company cannot and will not be liable for any loss or damage
        arising from breaches of security and confidentiality because of your
        fault.
      </p>

      <h2>6. RISKS</h2>

      <p>
        <strong>
          Third Party Services and Websites. You acknowledge and agree that the
          Company is not responsible for the third-party services, applications,
          and website you use and interact with the help of the Keeper Wallet,
          and that you shall access the trustworthiness of any third-party
          websites, products, smart-contracts, or content you access or use
          through the Waves Keeper independently. You further expressly
          acknowledge that third party websites and services can be maliciously
          or negligently harmful, causing mistakes and/or failures of the Waves
          Keeper operation and that the Company cannot be held liable for your
          interaction with such third party websites and services and related
          loss of property or even identity.
        </strong>
      </p>

      <p>
        <strong>
          Risk of Blockchain Technology Usage. You understand that blockchain
          technologies including the Waves blockchain technology and other
          associated and related technologies are
        </strong>
      </p>

      <p>
        new and untestedand outside of your or the Company’s control and adverse
        changes in market forces or the technology, broadly construed, will
        excuse the nonperformance by the Company under these Terms including
        temporary interruption or permanent termination of your access to
        Services,forks, rollbacks or bugs causing loss of your crypto assets. By
        utilizing the Services in any way, you represent that you understand the
        inherent risks associated with blockchain systems; and warrant that you
        understand the usage and intricacies of blockchain-based software
        systems and crypto-assets.
      </p>

      <p>
        <strong>
          Risk of Software Weaknesses. You understand and accept that there is
          no warranty that the Services will be uninterrupted or error-free. It
          may contain weaknesses and vulnerabilities. If you find out any breach
          of security, you must notify us.
        </strong>
      </p>

      <p>
        <strong>
          Risk of Theft or Unauthorized Access. You understand and accept that
          the Keeper Wallet (i.e. the Waves, Bitcoin and Ethereum blockchains,
          etc.) may be exposed to attacks by hackers or other individuals that
          could result in theft or loss of the your crypto-assets.
        </strong>
      </p>

      <p>
        <strong>
          Internet transmission and failures in functionality. You acknowledge
          that there are risks associated with utilizing the Keeper Wallet
          including, but not limited to, the failure of hardware, software, and
          Internet connections. You acknowledge that the Company shall not be
          responsible for any communication failures, disruptions, errors,
          distortions or delays you may experience whentrading via the Waves
          Keeper, however caused. You acknowledge that there are risks inherent
          in Internet connectivity and technologies that could result in the
          loss of your privacy, a confidential information and property.
        </strong>
      </p>

      <p>
        <strong>
          Unfavorable regulatory environment. Cryptocurrencies and blockchain
          technologies have been the subject of scrutiny by various regulatory
          bodies around the world. The functioning and maintenance of the
          Services could be impacted by one or more regulatory inquiries or
          actions, including but not limited to restrictions of use of crypto
          assets. If so, the using of the Services may become illegal in some
          jurisdictions. It is your responsibility to be compliant with the
          applicable laws.
        </strong>
      </p>

      <h2>7. INDEMNITY</h2>

      <p>
        You agree to release and to indemnify, defend and hold harmless the
        Company and its parents, subsidiaries, affiliates and agencies, as well
        as the officers, directors, employees, shareholders andrepresentatives
        of any of the foregoing entities, from and against any and all losses,
        liabilities, expenses, damages, costs (including attorneys’ fees and
        court costs) claims or actions of any kindwhatsoever arising or
        resulting from your use of the Services, your violation of these Terms
        of Use, and any of your acts or omissions that implicate publicity
      </p>

      <p>
        rights, defamation or invasion of privacy. The Company reserves the
        right, at its own expense,
      </p>

      <p>
        to assume exclusive defense and control of any matter otherwise subject
        to indemnification by you and, in such case, you agree to cooperate with
        the Company in the defense of such matter.
      </p>

      <h2>8. LIMITATION ON LIABILITY</h2>

      <p>
        YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY FORYOUR
        USE OF THE SERVICES. YOU ACKNOWLEDGE AND AGREE THAT ANY INFORMATION YOU
        SEND OR RECEIVE DURING YOUR USE OF THE SERVICES MAYNOT BE SECURE AND MAY
        BE INTERCEPTED OR LATER ACQUIRED BY UNAUTHORIZED PARTIES. YOU
        ACKNOWLEDGE AND AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR OWN RISK.
        RECOGNIZING SUCH, YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT
        PERMITTED BY APPLICABLE LAW, NEITHER COMPANY NOR ITS AFFILIATED PERSONS
        OR REPRESENTATIVES WILL BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT,
        INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY OR OTHER DAMAGES
        OF ANY KIND, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS,
        GOODWILL, USE, DATA OR OTHER TANGIBLE OR INTANGIBLE LOSSES OR ANY OTHER
        DAMAGES BASED ON CONTRACT, TORT, STRICT LIABILITY OR ANY OTHER GROUND
        (EVEN IF COMPANY HAD BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
        RESULTING FROM THE SERVICE; THE USE OR THE INABILITY TO USE SERVICE;
        UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONSOR DATA;
        STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; ANY ACTIONS WE
        TAKE OR FAIL TO TAKE AS A RESULT OF COMMUNICATIONS YOU SEND TO US; HUMAN
        ERRORS; TECHNICAL MALFUNCTIONS; FAILURES, INCLUDING PUBLIC UTILITY OR
        INTERNET OUTAGES; OMISSIONS, INTERRUPTIONS, LATENCY, DELETIONS OR
        DEFECTS OF ANY DEVICE OR NETWORK, PROVIDERS, OR SOFTWARE; ANY INJURY OR
        DAMAGE TO COMPUTER EQUIPMENT; INABILITY TO FULLY ACCESS THE SERVICES OR
        USE THE SERVICES AT ANY WEBSITE; THEFT, TAMPERING, DESTRUCTION, OR
        UNAUTHORIZED ACCESS TO, IMAGES OR OTHER CONTENT OF ANY KIND; DATA THAT
        IS PROCESSED LATE OR INCORRECTLY OR IS INCOMPLETE OR LOST;
        TYPOGRAPHICAL, PRINTING OR OTHER ERRORS, OR ANY COMBINATION THEREOF; OR
        ANY OTHER MATTER RELATING TO THE SERVICES.
      </p>

      <p>
        The Company shall not have any liability or responsibility for any
        errors or omissions in performance of the Services, for your action or
        inaction in connection with the Services or for any damage to your
        computer or data or funds or any other damage you may incur in
        connection withthe Services. Your use of the Services is at your own
        risk. In no event shall the Company be liable for any direct, indirect,
        punitive, incidental, special or consequential damages arising out of or
        in any way connected with the use of the Services, the delay or
      </p>

      <p>
        inability to use the Services or otherwise arising in connection with
        the Services whether
      </p>

      <p>
        based on contract, tort, strict liability or otherwise, even if advised
        of the possibility of any such damages.
      </p>

      <p>
        You expressly understand and agree that your use of the Services is at
        your sole risk. The Services is provided on an “AS IS” and “as
        available” basis, without warranties of any kind, either express or
        implied, including, without limitation, implied warranties of
        merchantability, fitness for a particular purpose or non-infringement.
        You acknowledge that the Company has no control over, and no duty to
        take any action regarding: which users gain access to or use the
        Service; which third party websites and services you use the Services
        with; how you may use the Service; or what actions you may take with the
        help of the Services. You release the Company from all liability for you
        having acquired or not acquired content through the Services.
      </p>

      <p>
        You acknowledge that the Company is not responsible for transferring,
        safeguarding, or maintaining your private keys, the Keeper Wallet
        password or any digital currency associated therewith. If you lose,
        mishandle or have stolen your Keeper Wallet password or private keys,
        you acknowledge that you may not be able to recover digital currency
        accessible via such private keys and passwords, and that Company is not
        responsible for such loss. You acknowledge that the Company is not
        responsible for any loss, damage or liability arising from your failure
        to comply with the Terms hereunder.
      </p>

      <h2>9. INTELLECTUAL PROPERTY</h2>

      <p>
        We grant you limited, non-exclusive, non-transferable revocable
        permission to make use of the Keeper Wallet. This limited license to use
        the Keeper Wallet shall remain in effect until and unless terminated by
        us or by you.
      </p>

      <p>
        You acknowledge and agree that the Keeper Wallet contains Intellectual
        property that is protected by applicable laws. Except as expressly
        authorized by the Company, you agree not to copy, modify, rent, lease,
        loan, sell, distribute, perform, display or create derivative works
        based on the Keeper Wallet, in whole or in part.
      </p>

      <p>
        The Company software, including without limitation the Keeper Wallet, is
        not in any way sold or transferred to you.
      </p>

      <p>
        You cannot and have no right to use any Keeper Wallet trademarks,
        Services marks, trade names, logos, domain names, and any other features
        whether for commercial or non-commercial use.
      </p>

      <p>
        Third party software (for example, open-source software) included in the
        Keeper Wallet are made available to you under the relevant third-party
        software’s license terms.
      </p>

      <h2>10. PROHIBITED ACTIVITIES</h2>

      <p>
        You agree that you will not use the Services to perform any type or sort
        of illegal activity or to take any action that negatively affects the
        performances of the Services. You may not engage viathe Services of the
        following activities, nor help a third party in any such activity to:
      </p>

      <ul>
        <li>
          attempt to gain unauthorized access to our Services or another user’s
          orders or wallets;
        </li>

        <li>make any attempt to bypass or circumvent any security features;</li>
        <li>violate any law, statute, ordinance, regulation or these Terms;</li>

        <li>
          reproduce, duplicate, copy, sell or resell our Services for any
          purpose except as authorizedin these Terms;
        </li>

        <li>
          engage in any activity that is abusive or interferes with or disrupts
          our Services (forexample, DDoS attacks).
        </li>
      </ul>

      <h2>11. COMMUNICATIONS AND WRITTEN NOTICES</h2>

      <p>
        Unless the contrary is specifically provided in these Terms, any notice,
        instruction, request, or other communication to be given to us by the
        User under these Terms shall be in writing and shall be sent to the
        email address below:
      </p>

      <h2>12. COMPLAINTS AND DISPUTES</h2>

      <p>
        If an alleged breach, controversy, claim, dispute or difference (a
        “Dispute”) arises between you and us out of or in connection with your
        use of the Services and/or these Terms (including but not limited to the
        validity, performance, breach or termination thereof), the partiesshall
        seek to resolve the matter by negotiation by referring the matter first
        to:
      </p>

      <p>
        If you wish to report an error or a Dispute, you must send an email to
        the Keeper Wallet at:{' '}
        <a href="mailto:keeper@waves.tech">keeper@waves.tech</a>
      </p>

      <p>The following information will need to be included:</p>

      <ul>
        <li>your name and surname;</li>
        <li>your e-mail address (or other recognition details);</li>
        <li>a detailed enquiry description;</li>

        <li>
          <p>the date and time that the issue have arisen.</p>

          <p>
            If a situation arises which is not expressly covered by these Terms,
            the parties agree to try to resolve the matter on the basis of good
            faith and fairness and by taking such action as isconsistent with
            market practice.
          </p>
        </li>
      </ul>

      <h2>13. DISPUTE RESOLUTION, PLACE OF JURISDICTION AND GOVERNING LAW</h2>

      <p>
        These Terms shall exclusively be governed by and construed in accordance
        with the substantive laws of the Republic of Seychelles, as amended from
        time to time.
      </p>

      <p>
        In the event that the parties are unable to resolve a Dispute in
        accordance with section 12, then theparties hereby irrevocably submit
        such Dispute to the exclusive jurisdiction of the courts of the Republic
        of Seychelles.
      </p>

      <h2>14. FORCE MAJEURE</h2>

      <p>
        A Force Majeure Event includes without limitation each of the following:
      </p>

      <ul>
        <li>
          Government actions, the outbreak of war or hostilities, the threat of
          war, acts of terrorism, national emergency, riot, civil disturbance,
          sabotage, requisition, or any other international calamity, economic
          or political crisis;
        </li>

        <li>
          Act of God, earthquake, tsunami, hurricane, typhoon, accident, storm,
          flood, fire, epidemic or other natural disaster;
        </li>

        <li>Labour disputes and lock-out;</li>

        <li>
          Breakdown, failure or malfunction of any electronic, network and
          communication lines (not due to our fault);
        </li>

        <li>
          Any event, act or circumstances not reasonably within our control and
          the effect of that event(s) is such that Keeper Wallet support is not
          in a position to take any reasonable action to cure the default.
        </li>
      </ul>

      <h2>15. THIRD-PARTY WEBSITES AND CONTENT</h2>

      <p>
        The Services may be used to enable your interaction with websites,
        applications or services ownedor operated by parties other than the
        Company. The Company does not monitor or control resources outside the
        Services and is not responsible for their content, actions, or
        inactions. The Company does not control the third-party content,
        actions, customer support or lack thereof or monitor it for compliance
        with any requirement (e.g. truthfulness, integrity, legality).
        Accordingly,the Company does not bear any liability arisen in connection
        with your access or use of the third-party websites, applications, and
        services. The inclusion of links to third party resources does notimply
        any endorsement of the material in the Services or, unless expressly
        disclosed otherwise, any sponsorship, affiliation or association with
        its owner, operator or sponsor, nor does such inclusion of links imply
        that the Company is authorized to use any trade name, trademark, logo,
        legal or official seal, or copyrighted symbol that may be reflected in
        the linked website.
      </p>

      <h3>15.1 INTERACTION WITH SWOP.FI</h3>

      <p>
        The website{' '}
        <a href="https://swop.fi/" rel="noopener" target="_blank">
          https://swop.fi/
        </a>{' '}
        acts as a front-end to the decentralized Swop.fi Protocol provides
        access to a decentralized finance platform («Swop.Fi service»). Keeper
        Wallet allows to exchange tokens within Swop.Fi service.{' '}
      </p>

      <p>
        The User may be charged for transactions by Keeper Wallet. The amount,
        if so, depends on the sum of a transaction and will be showed to the
        User.
      </p>

      <p>
        The User will be charged by Swop.Fi service according to the Swop.Fi
        terms of use:{' '}
        <a href="https://swop.fi/docs/terms" rel="noopener" target="_blank">
          https://swop.fi/docs/terms
        </a>
      </p>

      <h3>15.2 INTERACTION WITH PUZZLE SWAP</h3>

      <p>
        The website{' '}
        <a href="https://puzzleswap.org/" rel="noopener" target="_blank">
          https://puzzleswap.org/
        </a>{' '}
        is transfer the User to its services. Keeper Wallet allows to exchange
        tokens within Puzzle Swap service.{' '}
      </p>

      <p>
        The User may be charged for transaction by Keeper Wallet. The amount, if
        so, depends on the sum of a transaction and will be showed to the User.
        The User will be charged by Puzzle Swap service according to its rules:{' '}
        <a href="https://puzzleswap.org/" rel="noopener" target="_blank">
          https://puzzleswap.org/
        </a>
      </p>

      <p>
        Keeper Wallet is not responsible for Swop.fi’s and Puzzle Swap’s work.
      </p>

      <h2>16. GENERAL INFORMATION Entire Agreement/assignment</h2>

      <p>
        These Terms (and any additional terms, rules and conditions of
        participation that Company may post with respect to the provision of the
        Services) constitute the entire agreement between you andCompany with
        respect to the Services and supersedes any prior agreements, oral or
        written, between you and Company. In the event of a conflict between
        these Terms and the additional terms, rules and conditions of
        participation, the latter will prevail over the Terms to the extent of
        the conflict. We may assign these Terms to our parent company,
        affiliate, or subsidiary, or in connection with a merger, consolidation,
        or sale or other disposition of all or substantially its assets.
      </p>

      <h2>Waiver and Severability of Terms</h2>

      <p>
        The failure of Company to exercise or enforce any right or provision of
        the Terms shall not constitute a waiver of such right or provision. If
        any provision of the Terms is found by an arbitrator or court of
        competent jurisdiction to be invalid, the parties nevertheless agree
        that the arbitrator or court should endeavor to give effect to the
        parties’ intentions as reflected in the provision, and the other
        provisions of the Terms remain in full force and effect.
      </p>
    </RunningText>
  );
}
