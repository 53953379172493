import {
  InstallAppStoreLink,
  InstallGooglePlayLink,
} from '../_core/mobileAppLinks/mobileAppLinks';
import { SectionDescription, SectionHeading } from './common';
import * as styles from './keeperMobile.module.css';

export function KeeperMobile() {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <SectionHeading>
          <span className={styles.fancyHeading}>Get Keeper</span> <br />
          on Mobile
        </SectionHeading>
        <SectionDescription>
          Control your crypto from anywhere! Connect to dApps and websites
          instantly via QR codes.
        </SectionDescription>

        <div className={styles.installationLinks}>
          <InstallGooglePlayLink />
          <InstallAppStoreLink />
        </div>
      </div>

      <div className={styles.illustration}>
        <div className={styles.pictureShadow} />
        <img
          alt=""
          className={styles.illustrationMobile}
          src={
            new URL('./mobileAppIllustrationSmall.webp', import.meta.url)
              .pathname
          }
        />
        <img
          alt=""
          className={styles.illustrationDesktop}
          src={
            new URL('./mobileAppIllustrationLarge.webp', import.meta.url)
              .pathname
          }
        />
      </div>
    </div>
  );
}
