import * as styles from './runningText.module.css';

export function RunningText({ children }: { children: React.ReactNode }) {
  return (
    <div className={styles.root}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
}

export function LastUpdate({ children }: { children: React.ReactNode }) {
  return <p className={styles.lastUpdate}>{children}</p>;
}
