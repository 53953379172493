import { IconLinkButton } from '../_core/iconButton';
import * as styles from './gotAnyQuestions.module.css';

export function GotAnyQuestions() {
  return (
    <div className={styles.root}>
      <h1 className={styles.heading}>
        Got any <span className={styles.fancyHeading}>questions?</span>
        <br />
        Feel free to ask!
      </h1>

      <div className={styles.card}>
        <IconLinkButton
          to="mailto:support@keeper-wallet.app"
          className={styles.emailLabel}
        >
          <img
            src={new URL('./mail.svg', import.meta.url).pathname}
            width={24}
            height={24}
          />
          support@keeper-wallet.app
        </IconLinkButton>
        <p>Our socials</p>

        <div className={styles.socialButtonGroup}>
          <IconLinkButton
            to="https://t.me/KeeperWallet"
            target="_blank"
            rel="noopener"
          >
            <img
              className={styles.socialButton}
              src={new URL('./telegram.svg', import.meta.url).pathname}
            />
          </IconLinkButton>
          <IconLinkButton
            to="https://docs.keeper-wallet.app"
            target="_blank"
            rel="noopener"
          >
            <img
              className={styles.socialButton}
              src={new URL('./docs.svg', import.meta.url).pathname}
            />
          </IconLinkButton>
          <IconLinkButton
            to="https://twitter.com/keeperwallet"
            target="_blank"
            rel="noopener"
          >
            <img
              className={styles.socialButton}
              src={new URL('./twitter.svg', import.meta.url).pathname}
            />
          </IconLinkButton>
        </div>
      </div>
    </div>
  );
}
