import { StrictMode } from 'react';
import { hydrateRoot, type Root } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { Entry } from './entry';
import { routes } from './routes';

invariant(window.__ENTRY_CONTEXT__);
const { browser, css, js, nonce, intercomAppId } = window.__ENTRY_CONTEXT__;
__webpack_nonce__ = nonce;

let root: Root | undefined;

function render() {
  const router = createBrowserRouter(routes);

  if (import.meta.webpackHot) {
    const unsubscribe = router.subscribe(newRouterState => {
      window.__staticRouterHydrationData = newRouterState;
    });

    import.meta.webpackHot.accept(['./routes'], () => {
      unsubscribe();
      router.dispose();
      render();
    });
  }

  const el = (
    <StrictMode>
      <Entry
        browser={browser}
        css={css}
        js={js}
        nonce={nonce}
        router={router}
        intercomAppId={intercomAppId}
      >
        <RouterProvider router={router} />
      </Entry>
    </StrictMode>
  );

  if (root) {
    root.render(el);

    if (import.meta.webpackHot) {
      router.navigate(router.state.location, {
        replace: true,
        preventScrollReset: true,
      });
    }
  } else {
    root = hydrateRoot(document, el);
  }
}

render();

if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/sw.js');
}
