import { LinkButton } from '../_core/button';
import { SectionDescription, SectionHeading } from './common';
import * as styles from './keeperWeb.module.css';

export function KeeperWeb() {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.pictureShadow} />
        <div className={styles.content}>
          <SectionHeading>
            <span className={styles.fancyHeading}>Start Working</span> <br />
            with Web Keeper
          </SectionHeading>
          <SectionDescription>
            A dashboard for all your crypto assets and investments. Enjoy the
            advanced capabilities of Keeper Wallet.
          </SectionDescription>

          <LinkButton to="https://web.keeper-wallet.app/" size="big">
            Log in
          </LinkButton>
        </div>

        <div className={styles.illustration}>
          <div className={styles.illustrationPicture}>
            <img
              alt=""
              src={
                new URL('./webKeeperIllustration.webp', import.meta.url)
                  .pathname
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
