import { ConnectWeb3 } from '../home/connectWeb3';
import { ExploreCrypto } from '../home/exploreCrypto';
import { GotAnyQuestions } from '../home/gotAnyQuestions';
import { HomeHero } from '../home/hero';
import { InstallMobileApp } from '../home/installMobileApp';
import { InstallWebExtension } from '../home/installWebExtension';
import { OtherBenefits } from '../home/otherBenefits';
import { Statistics } from '../home/statistics';
import { SwapTokens } from '../home/swapTokens';
import * as styles from './home.module.css';

export function HomePage() {
  return (
    <>
      <HomeHero />

      <section className={styles.statistics}>
        <Statistics />
      </section>

      <section className={styles.exploreCrypto}>
        <ExploreCrypto />
      </section>

      <section className={styles.connectWeb3}>
        <ConnectWeb3 />
      </section>

      <section className={styles.swapTokens}>
        <SwapTokens />
      </section>

      <section className={styles.otherBenefits}>
        <OtherBenefits />
      </section>

      <section className={styles.installMobileApp}>
        <InstallMobileApp />
      </section>

      <section className={styles.installWebExtension}>
        <InstallWebExtension />
      </section>

      <section className={styles.gotAnyQuestions}>
        <GotAnyQuestions />
      </section>
    </>
  );
}
