import clsx from 'clsx';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import * as styles from './button.module.css';

const SIZE_CLASSES = {
  medium: styles.root_medium,
  big: styles.root_big,
};

interface Props extends React.ComponentProps<typeof Link> {
  size: keyof typeof SIZE_CLASSES;
}

export const LinkButton = forwardRef<HTMLAnchorElement, Props>(
  function LinkButton({ className, size, ...otherProps }, ref) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <Link
        ref={ref}
        className={clsx(className, styles.root, SIZE_CLASSES[size])}
        {...otherProps}
      />
    );
  },
);
