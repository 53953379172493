import {
  InstallAppStoreLink,
  InstallGooglePlayLink,
} from '../_core/mobileAppLinks/mobileAppLinks';
import * as styles from './installMobileApp.module.css';

export function InstallMobileApp() {
  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>
        Use{' '}
        <span
          className={styles.fancyGradientText}
          style={{
            backgroundImage:
              'linear-gradient(180deg, #22fff2 30%, #0a23fc 50%, #030f2f 70%)',
          }}
        >
          mobile
        </span>{' '}
        <span
          className={styles.fancyGradientText}
          style={{
            backgroundImage:
              'linear-gradient(170deg, #0a23fc 25%, #030f2f 40%, #fb36ff 60%)',
          }}
        >
          application
        </span>
      </h2>

      <p className={styles.description}>
        use our wallet on IOS or android platform
      </p>

      <div className={styles.links}>
        <InstallGooglePlayLink />
        <InstallAppStoreLink />
      </div>
    </div>
  );
}
