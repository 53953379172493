import * as styles from './swapTokens.module.css';

export function SwapTokens() {
  return (
    <div className={styles.content}>
      <div className={styles.pictureBox}>
        <div className={styles.pictureShadow}></div>
        <img
          className={styles.picture}
          alt=""
          src={new URL('./swapTokens.svg', import.meta.url).pathname}
        />
      </div>

      <div className={styles.info}>
        <h1 className={styles.heading}>
          <span className={styles.fancyHeading}>Swap Tokens</span>
          <br />
          with More Profit
        </h1>

        <p className={styles.description}>
          Exchange any tokens at the best rates possible. The built-in routing
          aggregator searches for the most profitable deals across all the
          services and liquidity pools on the Waves blockchain, while taking
          full advantage of their exclusive perks and bonuses.
        </p>
      </div>
    </div>
  );
}
