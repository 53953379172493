// extracted by mini-css-extract-plugin
export var appLink = "IjBWLUlUJKWbyy6BY7Zz";
export var description = "GhXVV9pt5cTRRCqOvfzQ";
export var descriptionAndAppLink = "TaYkAuBLY8WG_ucOt0B7";
export var fancyGradientText = "v453Ow4WS5B8Mom9mtBo";
export var heading = "F6mtj5PlVQJDIhKIcln0";
export var image = "vjAZ9I7zWzs5N_tPhAn2";
export var install = "UI9OeC_xzeByy49xvHcD";
export var installBox = "cgYoBfvgTy_gxnvkta0B";
export var installBoxCta = "Fvnbk0DpqilYzL8kclhY";
export var installBoxCtaAccent = "JXBpzA9Aa9vW70i6QM9r";
export var installBoxCtaPart = "GbENQqF9w2bsNT0F8yKt";
export var installBoxCta_mobile = "qmItJCYQ5jjXLksoKXgl";
export var installBoxCta_webExtension = "CjBboubnNoUKs5wjwawJ";
export var installBoxLinks = "lRkkJ7trQcvidFyjvzpi";
export var installLinkWebExtension = "lvDJYopSSHHPW_yruv6w";
export var root = "VaxGQfacOtS0xcTNlKkk";