import clsx from 'clsx';

import * as styles from './common.module.css';

export function SectionHeading({
  className,
  ...props
}: React.ComponentProps<'h2'>) {
  return <h2 {...props} className={clsx(styles.heading, className)} />;
}

export function SectionDescription({
  className,
  ...props
}: React.ComponentProps<'p'>) {
  return <p {...props} className={clsx(styles.description, className)} />;
}
