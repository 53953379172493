import { LinkButton } from '../_core/button';
import { WEB_EXTENSION_INFO_BY_BROWSER } from '../_core/constants';
import { useEntryContext } from '../entry';
import * as styles from './keeperExtension.module.css';

export function KeeperExtension() {
  const { browser } = useEntryContext();

  const webExtensionInfo =
    WEB_EXTENSION_INFO_BY_BROWSER[browser === 'unknown' ? 'chrome' : browser];

  return (
    <div className={styles.root}>
      <h2 className={styles.heading}>
        Install Keeper{' '}
        <span className={styles.headingAccent}>for your browser</span>
      </h2>
      <p className={styles.description}>
        Keeper Wallet is High-security wallet extension with swapping
        capabilities point to the Waves blockchain its services.
      </p>

      <LinkButton
        to={webExtensionInfo.storeUrl}
        rel="noopener"
        size="big"
        target="_blank"
      >
        <img
          alt=""
          src={webExtensionInfo.logoPathname}
          width={24}
          height={24}
        />
        Install in your browser
      </LinkButton>

      <h3 className={styles.otherBrowsersHeading}>
        Extensions for others are also available.
      </h3>

      <nav className={styles.otherBrowsersLinks}>
        {Object.entries(WEB_EXTENSION_INFO_BY_BROWSER)
          .filter(([id]) => id !== browser)
          .map(([id, info]) => (
            <a
              key={id}
              className={styles.otherBrowsersLinksItem}
              href={info.storeUrl}
              rel="noopener"
              target="_blank"
            >
              <img alt="" src={info.logoPathname} width={32} height={32} />
            </a>
          ))}
      </nav>
    </div>
  );
}
