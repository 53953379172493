import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import { LinkButton } from '../_core/button';
import { GotAnyQuestions } from '../home/gotAnyQuestions';
import { Domains } from '../products/domains';
import { KeeperDesktop } from '../products/keeperDesktop';
import { KeeperExtension } from '../products/keeperExtension';
import { KeeperMobile } from '../products/keeperMobile';
import { KeeperWeb } from '../products/keeperWeb';
import { SwapWidget } from '../products/swapWidget';
import * as styles from './products.module.css';

function Anchors({
  items,
}: {
  items: Array<{ elementId: string; label: string }>;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const anchorsByIdRef = useRef<Record<string, HTMLAnchorElement | null>>({});

  const [visibleElementId, setVisibleElementId] = useState<string | null>(null);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    const onScroll = () => {
      clearTimeout(timeoutId);

      let visibleElement: string;

      for (const item of items) {
        const element = document.getElementById(`section-${item.elementId}`);

        if (element) {
          const rect = element.getBoundingClientRect();

          if (rect.height + rect.top > window.innerHeight / 3) {
            visibleElement = item.elementId;
            setVisibleElementId(visibleElement);
            break;
          }
        }
      }

      timeoutId = setTimeout(() => {
        if (visibleElement == null) {
          return;
        }

        const anchorElement = anchorsByIdRef.current[visibleElement];

        if (anchorElement == null || containerRef.current == null) {
          return;
        }

        containerRef.current?.scrollTo({
          left:
            anchorElement.offsetLeft -
            window.innerWidth / 2 +
            anchorElement.getBoundingClientRect().width / 2,
          behavior: 'smooth',
        });
      }, 100);
    };

    addEventListener('scroll', onScroll);

    return () => {
      clearTimeout(timeoutId);
      removeEventListener('scroll', onScroll);
    };
  }, [items]);

  return (
    <div className={styles.anchors} ref={containerRef}>
      {items.map(({ elementId, label }) => {
        const isActive = visibleElementId === elementId;

        return (
          <LinkButton
            ref={element => {
              anchorsByIdRef.current[elementId] = element;
            }}
            key={elementId}
            to={`#${elementId}`}
            size="medium"
            className={clsx(styles.anchorButton, {
              [styles.anchorButton_active]: isActive,
            })}
          >
            {label}
          </LinkButton>
        );
      })}
    </div>
  );
}

export function ProductsPage() {
  return (
    <>
      <h1 className={styles.pageHeading}>Products</h1>

      <Anchors
        items={[
          {
            label: 'Start Working',
            elementId: 'start-working',
          },
          {
            label: 'Get Keeper on Mobile',
            elementId: 'get-keeper-on-mobile',
          },
          {
            label: 'Get Keeper for Your Desktop',
            elementId: 'get-keeper-for-your-desktop',
          },
          {
            label: 'Integrate Swap Widget',
            elementId: 'integrate-swap-widget',
          },
          {
            label: 'Discover Domains',
            elementId: 'discover-domains',
          },
        ]}
      />

      <section>
        <KeeperExtension />
      </section>

      <section id="section-start-working">
        <div id="start-working" />
        <KeeperWeb />
      </section>

      <section id="section-get-keeper-on-mobile">
        <div
          className={styles.anchorGetKeeperOnMobile}
          id="get-keeper-on-mobile"
        />
        <KeeperMobile />
      </section>

      <section id="section-get-keeper-for-your-desktop">
        <div
          className={styles.anchorGetKeeperForYourDesktop}
          id="get-keeper-for-your-desktop"
        />
        <KeeperDesktop />
      </section>

      <section id="section-integrate-swap-widget">
        <div
          className={styles.anchorIntegrateSwapWidget}
          id="integrate-swap-widget"
        />
        <SwapWidget />
      </section>

      <section id="section-discover-domains">
        <div className={styles.anchorDiscoverDomains} id="discover-domains" />
        <Domains />
      </section>

      <section>
        <GotAnyQuestions />
      </section>
    </>
  );
}
