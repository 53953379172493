// extracted by mini-css-extract-plugin
export var burgerMenu = "MUuWLzg0Ydo9RhxIH5AS";
export var burgerMenuAppLink = "edP8u7ViI_z4wU13bJ0Q";
export var burgerMenuCloseButton = "vjM9SLc5HZXJ3kShNYM1";
export var burgerMenuHeader = "JjTVFGXTFiyGh0jImv0N";
export var burgerMenuItem = "tQyo2Iom0ArapE93EY7T";
export var burgerMenuNav = "tfQbrDRSowLMleUFyHCx";
export var burgerMenuOpenButton = "QIYpcqX6W3elOHzUHZtL";
export var copyright = "X9L508xDlZjBmYtX2811";
export var footer = "pGyc5dam6mktUwYw7QI5";
export var footerContent = "z1pTqASdgcwzMgIz7m7l";
export var footerLegalNav = "Qxdh4fuwbi7X0mlS7tjA";
export var footerLegalNavLink = "mruzYII1Hc3LXSQFVjqu";
export var footerLogo = "D9dYD0iwKP0Y457ql77i";
export var footerLogoImg = "yLaezeKC0ujHWUNCM5aO";
export var footerMainNav = "enUTzdiRsGAz3K28luri";
export var footerMainNavLink = "YKrG_52gk3YbpHIuGUl5";
export var footerSideNav = "F_dKnAoYPUsqsww110IA";
export var footerSideNavLink = "V3Jn3W6Gdr5zM_FRcXBl";
export var footerSocialNav = "YqQgj8edLXw_j2yq6kEa";
export var header = "zVWfO4n0rDpJ7ZhMP2EX";
export var headerEnd = "gLERwuAK0krHW3s2QDHX";
export var headerLogo = "F1S7s93CIsYP6nGlmPHl";
export var headerLogoImg = "IazPbB0EnM4aLmFBG6ty";
export var main = "i4B1rupVWJ5oLEJ4VQmz";
export var menu = "zq2QTP5CFAM5934lVrF5";
export var menuItem = "NeAFLRypYdp2hOtGKon4";
export var root = "j5L1JLgTdfeTdJR67Kpu";