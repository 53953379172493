import * as styles from './mobileAppLinks.module.css';

export function InstallGooglePlayLink() {
  return (
    <a
      aria-label="Google play"
      className={styles.googlePlayLink}
      href="https://play.google.com/store/apps/details?id=app.keeper.wallet"
      rel="noopener"
      target="_blank"
    />
  );
}

export function InstallAppStoreLink() {
  return (
    <a
      aria-label="App Store"
      className={styles.appStoreLink}
      href="https://apps.apple.com/us/app/keeper-wallet/id6444443920"
      rel="noopener"
      target="_blank"
    />
  );
}
