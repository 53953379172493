import { ErrorResponse } from '@remix-run/router';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

import { type MetaFunctionArgs } from '../_core/meta';

function convertErrorToErrorResponse(error: unknown) {
  return isRouteErrorResponse(error)
    ? error
    : new ErrorResponse(
        500,
        'Internal Server Error',
        'Something went terribly wrong on our side.',
      );
}

export function errorMeta({ error }: MetaFunctionArgs) {
  if (!error) return;

  const { statusText } = convertErrorToErrorResponse(error);

  return {
    title: statusText,
  };
}

export function ErrorPage() {
  const error = useRouteError();
  const errorResponse = convertErrorToErrorResponse(error);

  return (
    <>
      <h1>{errorResponse.statusText}</h1>
      {typeof errorResponse.data === 'string' && <p>{errorResponse.data}</p>}
    </>
  );
}
