import {
  type IndexRouteObject,
  type NonIndexRouteObject,
  Outlet,
} from 'react-router-dom';

import { type MetaDescriptor, type MetaFunctionArgs } from './_core/meta';
import { Document } from './document';
import { Layout } from './layout/layout';
import { errorMeta, ErrorPage } from './pages/_error';
import { HomePage } from './pages/home';
import { PrivacyPolicyPage } from './pages/privacyPolicy';
import { ProductsPage } from './pages/products';
import { TermsOfUsePage } from './pages/termsOfUse';

interface AppRouteParams {
  meta?: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ((args: MetaFunctionArgs<any>) => MetaDescriptor | void) | MetaDescriptor;
}

type AppIndexRouteInput = IndexRouteObject & AppRouteParams;

type AppNonIndexRouteInput = Omit<NonIndexRouteObject, 'children' | 'id'> & {
  children?: AppRouteInput[];
} & AppRouteParams;

type AppRouteInput = AppIndexRouteInput | AppNonIndexRouteInput;
type AppRouteObject = AppRouteInput & { id: string };

function convertRoutes(
  routes: AppRouteInput[],
  parentPath: number[] = [],
): AppRouteObject[] {
  return routes.map((route, index) => {
    const treePath = [...parentPath, index];
    const id = treePath.join('-');

    return route.index
      ? { ...route, id }
      : {
          ...route,
          id,
          children: route.children
            ? convertRoutes(route.children, treePath)
            : undefined,
        };
  });
}

export const routes = convertRoutes([
  {
    path: '/',
    meta: {
      title: 'Keeper Wallet',
      description:
        'Keeper Wallet is High-security wallet extension with swapping capabilities to the Waves blockchain its services.',
    },
    element: (
      <Document>
        <Outlet />
      </Document>
    ),
    errorElement: (
      <Document>
        <ErrorPage />
      </Document>
    ),
    children: [
      {
        meta: errorMeta,
        element: (
          <Layout>
            <Outlet />
          </Layout>
        ),
        errorElement: (
          <Layout>
            <ErrorPage />
          </Layout>
        ),
        children: [
          {
            index: true,
            element: <HomePage />,
          },
          {
            path: '/products',
            element: <ProductsPage />,
          },
          {
            path: '/privacy-policy',
            element: <PrivacyPolicyPage />,
          },
          {
            path: '/terms-of-use',
            element: <TermsOfUsePage />,
          },
          {
            path: '*',
            loader: () => {
              throw new Response("The page you're looking for doesn't exist.", {
                status: 404,
                statusText: 'Page Not Found',
              });
            },
            element: null,
          },
        ],
      },
    ],
  },
]);
