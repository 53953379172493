import { LastUpdate, RunningText } from '../_core/runningText';

export function PrivacyPolicyPage() {
  return (
    <RunningText>
      <h1>Privacy Notice</h1>

      <LastUpdate>Last Update: February 18, 2022</LastUpdate>

      <p>
        The Privacy Notice (hereinafter - Notice) is a core part of the Keeper
        Wallet (hereinafter – the Service) Terms of Use. This document describes
        how we collect, use, share, and delete the data we receive from you
        because you are using our web browser extension “Keeper Wallet”. Any
        changes will be made available at the website{' '}
        <a
          href="https://s3.eu-central-
  1.amazonaws.com/waves.tech/Privacy_Policy_Waves_Keeper_fin_DL_Tech_9ce50e1fe0.pdf"
          rel="noopener"
          target="_blank"
        >
          https://s3.eu-central-
          1.amazonaws.com/waves.tech/Privacy_Policy_Waves_Keeper_fin_DL_Tech_9ce50e1fe0.pdf
        </a>
      </p>

      <p>
        DLTech Ltd (reg. number 216295) (hereinafter referred to as “We”)
        understands that your privacy is important and that you care about how
        your personal data is used. Accordingly, we respect and value the
        privacy of all our users and will only collect and use personal data in
        ways that are described here and in a way that is consistent with our
        obligations and your rights under this Privacy Notice.
      </p>

      <h2>1. What is the data we may collect about you or your usage?</h2>

      <p>
        Personal data is, in simpler terms, any information about you that
        enables you to be identified. Personal data covers obvious information
        such as your name and contact details, but it also covers less obvious
        information such as identification numbers, electronic location data,
        and other online identifiers.
      </p>

      <p>
        We do not collect your personal information like name, passport number,
        tax identification number, etc. Instead, all your personal information,
        including your seed phrase and wallet, is kept on your device (laptop,
        smartphone or whatever, where you installed the Keeper Wallet).
      </p>

      <p>We collect only the following metrics about all users anonymously:</p>

      <table>
        <thead>
          <tr>
            <th>Event (data) collected</th>
            <th>Meaning</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>installKeeper</td>
            <td>The point of installation</td>
          </tr>

          <tr>
            <td>openKeeper</td>
            <td>Open UI</td>
          </tr>

          <tr>
            <td>idleKeeper</td>
            <td>The extension is not used</td>
          </tr>

          <tr>
            <td>initVault</td>

            <td>
              The point of an encrypted storage creation, the password was set
            </td>
          </tr>

          <tr>
            <td>addWallet</td>
            <td>The point of creation/import of a new account</td>
          </tr>

          <tr>
            <td>allowOrigin</td>
            <td>a website access is granted</td>
          </tr>

          <tr>
            <td>disableOrigin</td>
            <td>a website access is denied</td>
          </tr>

          <tr>
            <td>approve</td>

            <td>
              The point of User’s confirmation of a transaction or the point of
              User’s authorization
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        Depending upon your use of Keeper Wallet, we may collect and hold these
        pieces of information. It is impossible to personify you by using this
        information. We do not collect any other data that is directly or
        indirectly associated with you or your usage of the Keeper Wallet.
      </p>

      <p>
        We do not collect any ‘special category’ or ‘sensitive’ personal data
        and/or personal data relating to children, etc. However, we may also
        collect the information that you provide to us, i.e. your e-mail, in the
        case of your communication with us by e-mail.
      </p>

      <h2>2. How do we use the mentioned data?</h2>

      <p>
        We must always have a lawful basis for using any data. The data on the
        events mentioned earlier are used for statistical monitoring and
        analysis of the extension performance. The information you provide to us
        is used to fulfil your request.
      </p>

      <p>
        We will only use your data for the purpose(s) for which it was
        originally collected unless we reasonably believe that another purpose
        is compatible with that or those original purpose(s) and need to use
        your data for that purpose.
      </p>

      <p>
        If we need to use your data for a purpose that is unrelated to or
        incompatiblewith the purpose(s)for which it was originally collected, we
        will inform you and will explain the legal basis which allows us to do
        so.
      </p>

      <h2>3. How long will we keep the mentioned data?</h2>

      <p>
        We will not keep your data for any longer than is necessary in light of
        the reason(s) for which it was first collected. Your personal data will
        therefore be kept for the following periods (or, where there is no fixed
        period, the following factors will be used to determine how long it is
        kept):
      </p>

      <table>
        <thead>
          <tr>
            <th>Type of Data</th>
            <th>How Long We Keep It</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Events</td>
            <td>For the period of analysis such information only</td>
          </tr>

          <tr>
            <td>Information that you provide to us</td>

            <td>
              As long as we need them to fulfil your request or if there are any
              legal obligations for the length, we are prescribed to store them.
            </td>
          </tr>
        </tbody>
      </table>

      <h2>4. How may the user access the data?</h2>

      <p>
        We collect the data that is depersonified. So, we cannot divide what
        kind of data relates to you.
      </p>

      <h2>5. How and where do we store or transfer the data?</h2>

      <p>
        We do not share any data with anyone that do not have any legal basis
        for collecting such data. The security of all information we have is
        essential to us, and to protect it, we take technical measures.
      </p>

      <p>
        If we sell, transfer, or merge parts of our business or assets (Keeper
        Wallet), the data may be transferred to a third party. Any new owner of
        our business or assets (Keeper Wallet) may continue to use your personal
        data in the same way(s) that we have used it, as specified in this
        Privacy Notice.
      </p>

      <h2>6. How can the user contact us?</h2>

      <p>
        To contact us about anything to do with data and data protection, please
        use the following details:{' '}
        <a href="mailto:support@waves.tech">support@waves.tech</a>.
      </p>

      <h2>7. Changes to this Privacy Notice</h2>

      <p>
        We may change this Privacy Notice from time to time. This may be
        necessary, for example, if the lawchanges or if we change our business
        in away that affects personal data protection.
      </p>
    </RunningText>
  );
}
