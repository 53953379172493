import { useState } from 'react';
import { Link } from 'react-router-dom';

import { BottomSheet } from '../_core/bottomSheet';
import { LinkButton } from '../_core/button';
import * as styles from './layout.module.css';

interface Props {
  children: React.ReactNode;
}

export function Layout({ children }: Props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <Link className={styles.headerLogo} to="/">
          <img
            className={styles.headerLogoImg}
            alt="Home page"
            src={new URL('./keeperLogo.svg', import.meta.url).pathname}
            width={150}
            height={40}
          />
        </Link>

        <button
          className={styles.burgerMenuOpenButton}
          aria-label="Menu"
          type="button"
          onClick={() => {
            setIsMenuOpen(true);
          }}
        />

        <nav className={styles.menu}>
          <Link className={styles.menuItem} to="/products">
            Products
          </Link>

          <Link className={styles.menuItem} to="/support">
            Support
          </Link>
        </nav>

        <div className={styles.headerEnd}>
          <LinkButton to="http://web.keeper-wallet.app" size="medium">
            Go to the app
          </LinkButton>
        </div>

        <BottomSheet
          isOpen={isMenuOpen}
          onClose={() => {
            setIsMenuOpen(false);
          }}
        >
          <div className={styles.burgerMenu}>
            <header className={styles.burgerMenuHeader}>
              <button
                aria-label="Close"
                className={styles.burgerMenuCloseButton}
                type="button"
                onClick={() => {
                  setIsMenuOpen(false);
                }}
              >
                <img
                  alt=""
                  src={new URL('./close.svg', import.meta.url).pathname}
                />
              </button>
            </header>

            <nav className={styles.burgerMenuNav}>
              <Link className={styles.burgerMenuItem} to="/products">
                Products
              </Link>

              <Link className={styles.burgerMenuItem} to="/support">
                Support
              </Link>
            </nav>

            <LinkButton
              className={styles.burgerMenuAppLink}
              to="http://web.keeper-wallet.app"
              size="big"
            >
              Go to the app
            </LinkButton>
          </div>
        </BottomSheet>
      </header>

      <main className={styles.main}>{children}</main>

      <footer className={styles.footer}>
        <div className={styles.footerContent}>
          <Link className={styles.footerLogo} to="/">
            <img
              className={styles.footerLogoImg}
              alt="Home page"
              src={new URL('./keeperLogo.svg', import.meta.url).pathname}
              width={150}
              height={40}
            />
          </Link>

          <nav className={styles.footerMainNav}>
            <Link className={styles.footerMainNavLink} to="/support">
              Support
            </Link>
          </nav>

          <nav className={styles.footerSideNav}>
            <a
              className={styles.footerSideNavLink}
              href="https://docs.keeper-wallet.app/"
              rel="noopener"
              target="_blank"
            >
              Documentation
            </a>

            <a
              className={styles.footerSideNavLink}
              href="https://github.com/Keeper-Wallet/Keeper-Wallet-Extension"
              rel="noopener"
              target="_blank"
            >
              Github
            </a>
          </nav>

          <nav className={styles.footerSocialNav}>
            <a href="https://t.me/KeeperWallet" rel="noopener" target="_blank">
              <img
                alt="Telegram"
                src={new URL('./telegram.svg', import.meta.url).pathname}
              />
            </a>

            <a
              href="https://twitter.com/keeperwallet"
              rel="noopener"
              target="_blank"
            >
              <img
                alt="Twitter"
                src={new URL('./twitter.svg', import.meta.url).pathname}
              />
            </a>

            <a
              href="https://github.com/Keeper-Wallet/Keeper-Wallet-Extension"
              rel="noopener"
              target="_blank"
            >
              <img
                alt="Github"
                src={new URL('./github.svg', import.meta.url).pathname}
              />
            </a>

            <a
              href="https://keeperwallet.medium.com"
              rel="noopener"
              target="_blank"
            >
              <img
                alt="Medium"
                src={new URL('./medium.svg', import.meta.url).pathname}
              />
            </a>
          </nav>

          <nav className={styles.footerLegalNav}>
            <Link className={styles.footerLegalNavLink} to="/privacy-policy">
              Privacy Policy
            </Link>

            <Link className={styles.footerLegalNavLink} to="/terms-of-use">
              Terms of Service
            </Link>
          </nav>

          <address className={styles.copyright}>©2023. Keeper Wallet</address>
        </div>
      </footer>
    </div>
  );
}
