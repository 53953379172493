import * as styles from './otherBenefits.module.css';

export function OtherBenefits() {
  return (
    <div className={styles.otherBenefits}>
      <h2 className={styles.sectionHeading}>
        Other benefits
        <br /> of <span className={styles.gradientText}>Keeper Wallet</span>
      </h2>

      <div className={styles.otherBenefitsList}>
        <Benefit
          image={
            new URL('./otherBenefitsIcons/domains.svg', import.meta.url)
              .pathname
          }
          heading="Domains"
          description="Get unique NFTs that can be used as a name for your account."
        />
        <Benefit
          image={
            new URL('./otherBenefitsIcons/uniqueAccess.svg', import.meta.url)
              .pathname
          }
          heading="Unique Access to Different Services"
          description="contract mechanism ensures the constant growth of the USDCLP price."
        />
        <Benefit
          image={
            new URL('./otherBenefitsIcons/addressBook.svg', import.meta.url)
              .pathname
          }
          heading="Address Book"
          description="Favorite your most used addresses to streamline transactions."
        />
        <Benefit
          image={
            new URL('./otherBenefitsIcons/txHistory.svg', import.meta.url)
              .pathname
          }
          heading="Smart Transaction History"
          description="Filter transactions by multiple categories, including their purpose and route composition."
        />
        <Benefit
          image={
            new URL('./otherBenefitsIcons/nftCollection.svg', import.meta.url)
              .pathname
          }
          heading="NFT Collection"
          description="Keeper has a dedicated space for all your NFT goodness. Store, send and receive your prized digital artworks, and view the collection at any time."
        />
        <Benefit
          image={
            new URL('./otherBenefitsIcons/support.svg', import.meta.url)
              .pathname
          }
          heading="24/7 Customer Support"
          description="Contact our support team anytime, anywhere, for any questions."
        />
        <Benefit
          image={
            new URL(
              './otherBenefitsIcons/multiplePlatforms.svg',
              import.meta.url,
            ).pathname
          }
          heading="Multiple Platforms"
          description="Use Keeper on any device however you want: desktop or mobile, browser or app."
        />
      </div>
    </div>
  );
}

function Benefit({
  image,
  heading,
  description,
}: {
  image: string;
  heading: string;
  description: string;
}) {
  return (
    <div>
      <img className={styles.otherBenefitsListItemIcon} src={image} alt="" />
      <h3 className={styles.otherBenefitsListItemHeading}>{heading}</h3>
      <div className={styles.otherBenefitsListItemDescription}>
        {description}
      </div>
    </div>
  );
}
