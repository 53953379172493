import { LinkButton } from '../_core/button';
import { SectionDescription, SectionHeading } from './common';
import * as styles from './domains.module.css';

export function Domains() {
  return (
    <div className={styles.root}>
      <div className={styles.shadow} />
      <div className={styles.content}>
        <SectionHeading>
          <span className={styles.fancyHeading}>Discover</span> Domains
        </SectionHeading>
        <SectionDescription>
          Get unique NFT names and use them to streamline crypto transactions of
          all kinds.
        </SectionDescription>

        <LinkButton to="https://waves.domains/" size="big">
          GET NFT NAME
        </LinkButton>
      </div>

      <div className={styles.illustration}>
        <img
          alt=""
          src={new URL('./domainsIllustration.webp', import.meta.url).pathname}
        />
      </div>
    </div>
  );
}
