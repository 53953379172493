// extracted by mini-css-extract-plugin
export var appLink = "Q4a4YReKA05OZUn7SaNa";
export var background = "AWMMEdOjLalRod18crfl";
export var description = "rpReM_FZyLaXmVVwIXvV";
export var fancyHeading = "YoMSYC_0bvFFIuzDdo31";
export var heading = "ogYmd5cvDvbjK2GRlMd8";
export var pill = "NnCSvQE7k9ShrSc4hVub";
export var pillBox = "hF8pHiG_Uc0ldbbZEpBW";
export var pillBoxRight = "TAIOETbB2qqy_QfSxkxW";
export var pillBtc = "FeM1m8By82Yg6Y8uDhoQ";
export var pillBusd = "kY4GIGzT7ezk7ufd_9jg";
export var pillEth = "j_3UrHkd41l07fREM0gQ";
export var pillLogo = "UK_dkQU1Ynv6kUkTilNE";
export var pillLogoWithBorder = "WcplqFY6utfMN3_vl0UW";
export var pillShadow = "Opb6m7iWIwpSsIdkcHEx";
export var pillShadowLeft = "BByPOiYhWb6FZwT9sURL";
export var pillShadowLogo = "_ueFrS5kQ6z076InX2BC";
export var pillShadowText = "a2g9PQ8pBADEbTv3SSMt";
export var pillSwop = "L9kDESSiBHmzZaCMCkWL";
export var pillText = "cq2SG2uyVHHZMkCtfUeT";
export var pillUsdt = "TSha9NSGwbmBwNHK40n9";
export var pillWaves = "z_LlV3sHRKEMarZ_kGtu";