import clsx from 'clsx';

import { LinkButton } from '../_core/button';
import * as styles from './exploreCrypto.module.css';

export function ExploreCrypto() {
  return (
    <div className={styles.background}>
      <Pill
        className={styles.pillUsdt}
        logo={new URL('./usdt.svg', import.meta.url).pathname}
        text="USDT"
      />

      <Pill
        className={styles.pillBtc}
        logo={new URL('./btc.svg', import.meta.url).pathname}
        text="WBTC"
        shadowPosition="left"
      />

      <Pill
        className={styles.pillBusd}
        logo={new URL('./busd.svg', import.meta.url).pathname}
        text="BUSD"
      />

      <Pill
        className={styles.pillWaves}
        withBorder
        logo={new URL('./waves.svg', import.meta.url).pathname}
        text="WAVES"
        shadowPosition="left"
      />

      <Pill
        className={styles.pillEth}
        withBorder
        logo={new URL('./eth.svg', import.meta.url).pathname}
        text="ETH"
      />

      <Pill
        className={styles.pillSwop}
        logo={new URL('./swop.svg', import.meta.url).pathname}
        text="SWOP"
        shadowPosition="left"
      />

      <h1 className={styles.heading}>
        <span className={styles.fancyHeading}>Explore</span> Crypto
      </h1>

      <p className={styles.description}>
        Choose your favorite tokens from the hundreds supported by{' '}
        <strong>Keeper Wallet</strong>
      </p>

      <LinkButton
        className={styles.appLink}
        to="https://web.keeper-wallet.app"
        size="big"
      >
        Go to the app
      </LinkButton>
    </div>
  );
}

function Pill({
  className,
  logo,
  text,
  withBorder,
  shadowPosition = 'right',
}: {
  className?: string;
  logo: string;
  text: string;
  withBorder?: boolean;
  shadowPosition?: 'left' | 'right';
}) {
  return (
    <div
      className={clsx(
        styles.pillBox,
        {
          [styles.pillBoxRight]: shadowPosition === 'left',
        },
        className,
      )}
    >
      <div
        className={clsx(styles.pillShadow, {
          [styles.pillShadowLeft]: shadowPosition === 'left',
        })}
      >
        <div className={styles.pillShadowLogo}></div>
        <div className={styles.pillShadowText}>{text}</div>
      </div>

      <div className={styles.pill}>
        <img
          className={clsx(styles.pillLogo, {
            [styles.pillLogoWithBorder]: withBorder,
          })}
          alt=""
          src={logo}
        />

        <div className={styles.pillText}>{text}</div>
      </div>
    </div>
  );
}
