import { LinkButton } from '../_core/button';
import { SectionDescription, SectionHeading } from './common';
import * as styles from './keeperDesktop.module.css';

export function KeeperDesktop() {
  return (
    <div className={styles.root}>
      <div className={styles.pinkShadow} />
      <div className={styles.blueShadow} />
      <div className={styles.content}>
        <SectionHeading>
          <span className={styles.fancyHeading}>Get Keeper</span> for Your
          Desktop
        </SectionHeading>
        <SectionDescription className={styles.description}>
          Receive all advantages of a native app on your MAC or PC. Control your
          crypto assets like a pro.{' '}
        </SectionDescription>

        <LinkButton to="" size="big">
          Learn how to install
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.6067 0.698891C11.2019 0.10037 12.1668 0.10037 12.762 0.698891L24 12L12.762 23.3011C12.1668 23.8996 11.2019 23.8996 10.6067 23.3011C10.0115 22.7026 10.0115 21.7322 10.6067 21.1337L18.1653 13.5326H1.52405C0.682339 13.5326 0 12.8464 0 12C0 11.1536 0.682339 10.4674 1.52405 10.4674H18.1653L10.6067 2.86633C10.0115 2.26781 10.0115 1.29741 10.6067 0.698891Z"
              fill="white"
            />
          </svg>
        </LinkButton>

        <img
          alt=""
          className={styles.illustration}
          src={
            new URL('./desktopAppIllustration.webp', import.meta.url).pathname
          }
        />
      </div>
    </div>
  );
}
