import './document.module.css';

import { ScrollRestoration } from 'react-router-dom';

import { Meta } from './_core/meta';
import { BrowserGlobal } from './browserGlobal';
import { useEntryContext } from './entry';

export function Document({ children }: { children: React.ReactNode }) {
  const { browser, css, js, nonce, intercomAppId } = useEntryContext();

  return (
    <html lang="en" suppressHydrationWarning>
      <head suppressHydrationWarning>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="theme-color" content="#1f5af6" />
        <link rel="icon" href="/favicon.ico" sizes="any" />
        <link rel="icon" href="/icon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />

        <Meta />

        {css.map(href => (
          <link key={href} rel="stylesheet" href={href} />
        ))}

        {js.map(src => (
          <script
            key={src}
            defer
            nonce={nonce}
            src={src}
            suppressHydrationWarning
          />
        ))}

        {intercomAppId && (
          <script
            suppressHydrationWarning
            nonce={nonce}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `window.intercomSettings={api_base:"https://api-iam.intercom.io",app_id:"${intercomAppId}"},function(){var t=window,e=t.Intercom;if("function"==typeof e)e("reattach_activator"),e("update",t.intercomSettings);else{var a=document,n=function(){n.c(arguments)};n.q=[],n.c=function(t){n.q.push(t)},t.Intercom=n;var i=function(){var t=a.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://widget.intercom.io/widget/${intercomAppId}";var e=a.getElementsByTagName("script")[0];e.parentNode.insertBefore(t,e)};"complete"===document.readyState?i():t.attachEvent?t.attachEvent("onload",i):t.addEventListener("load",i,!1)}}();`,
            }}
          />
        )}
      </head>

      <body suppressHydrationWarning>
        {children}

        {typeof window !== 'undefined' && <ScrollRestoration />}

        <BrowserGlobal
          name="__ENTRY_CONTEXT__"
          nonce={nonce}
          value={{ browser, css, js, nonce, intercomAppId }}
        />
      </body>
    </html>
  );
}
