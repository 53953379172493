import { type HydrationState, type Router } from '@remix-run/router';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import invariant from 'tiny-invariant';

import { type BrowserName } from './_core/detectBrowser';

interface EntryContext {
  browser: BrowserName;
  css: string[];
  js: string[];
  nonce: string;
  routerState: HydrationState;
  intercomAppId: string | undefined;
}

const EntryContext = createContext<EntryContext | null>(null);

interface ProviderProps {
  browser: BrowserName;
  children: React.ReactNode;
  css: string[];
  js: string[];
  nonce: string;
  router: Router;
  intercomAppId: string | undefined;
}

export function Entry({
  browser,
  children,
  css,
  js,
  nonce,
  router,
  intercomAppId,
}: ProviderProps) {
  const [{ actionData, errors, loaderData }, setRouterState] = useState(
    router.state,
  );

  useEffect(() => {
    router.subscribe(setRouterState);
  }, [router]);

  const value = useMemo(
    (): EntryContext => ({
      browser,
      css,
      routerState: { actionData, errors, loaderData },
      nonce,
      js,
      intercomAppId,
    }),
    [actionData, browser, css, errors, js, loaderData, nonce, intercomAppId],
  );

  return (
    <EntryContext.Provider value={value}>{children}</EntryContext.Provider>
  );
}

export function useEntryContext() {
  const value = useContext(EntryContext);
  invariant(value, 'You must render EntryProvider higher in the tree');
  return value;
}
